<template>
  <div class="list-group-item" style="border: 0">
    <div class="title">
      <a :href="article.link" target="_blank">
        <h6>{{article.title}}</h6>
      </a>
    </div>
    <div v-if="article.content">
      <p class="content" v-html="article.content"></p>
    </div>
    <div class="mt-2">
      <span class="hostname text-primary">{{this.getHostname()}}</span>
      
      <span class="datetime text-primary float-right">{{this.getDateTime()}}</span>
    </div>
  </div>
</template>

<script>
const parseDate = tdate => {
  const systemDate = new Date(Date.parse(tdate));
  const userDate = new Date();

  const diff = Math.floor((userDate - systemDate) / 1000);
  if (diff < 59) {
    return diff + "s";
  }

  if (diff <= 3540) {
    return Math.round(diff / 60) + "m";
  }

  if (diff <= 86400) {
    return Math.round(diff / 3600) + "h";
  }

  if (diff < 604800) {
    return Math.round(diff / 86400) + "d";
  }

  return systemDate.toString().substring(4, 10);
};

export default {
  name: "Article",
  props: ["article"],
  methods: {
    getHostname() {
      try {
        const urlObj = new URL(this.article.link);
        return urlObj.hostname.replace("www.", "").replace("ww2.", "");
      } catch (e) {
        console.error(e.toString());
      }
    },
    getDateTime() {
      if (this.article.isoDate) {
        return parseDate(this.article.isoDate);
      }
    }
  }
};
</script>

<style scoped>

</style>